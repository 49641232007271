import {
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
    SxProps
} from '@mui/material'
import {
    CoreTypography,
    LeafConfirmationDialog,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { useEffect, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
    AssessmentAttemptSuccess,
    AssessmentItem,
    AssessmentType,
    ResponseInput,
    ResponseOptionCms,
    useSubmitResponseMutation
} from '../../../../graphql/generated/autogenerated'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import { ActivityTypeValueType } from '@thriveglobal/thrive-web-tracking/dist/Avo'

export interface AssessmentStepStyleProps {
    responseDirection?: 'row' | 'column'
    hideNavButtons?: boolean
    responseStyle?: 'radio' | 'button'
    autoProgress?: boolean
    skipConfirmationDialog?: boolean
}
export interface AssessmentStepProps extends AssessmentStepStyleProps {
    step: number
    assessmentItem: AssessmentItem
    prevStep: () => void
    nextStep: () => void
    assessmentAttempt: AssessmentAttemptSuccess
    setLatestAssessmentResponse: (AssessmentAttemptSuccess) => void
    retainProgress?: boolean
    sx?: SxProps
}

const messages = defineMessages({
    backButtonLabel: {
        defaultMessage: 'Back',
        description: 'Button label for going back to the previous page.'
    },
    nextButtonLabel: {
        defaultMessage: 'Next',
        description: 'Button label for proceeding to the next page.'
    },
    finishButtonLabel: {
        defaultMessage: 'Finish',
        description: 'Button label for the final question'
    },
    confirmationDialogTitle: {
        defaultMessage: 'Are you sure?',
        description:
            "Check if user is sure they'd like to continue with submitting their survey responses."
    },
    confirmationDialogContent: {
        defaultMessage:
            'Once you submit your responses you won’t be able to change them.',
        description:
            "Notice to users that they won't be able to change their answers once they continue."
    },
    confirmationDialogConfirmation: {
        defaultMessage: 'Confirm',
        description: 'Confirmation to continue'
    }
})

function responseOptionOrderFn(a: ResponseOptionCms, b: ResponseOptionCms) {
    if (a.displayOrder < b.displayOrder) {
        return -1
    } else if (a.displayOrder > b.displayOrder) {
        return 1
    }
    return 0
}

const AssessmentStep: React.FC<AssessmentStepProps> = ({
    step,
    assessmentItem,
    prevStep,
    nextStep,
    assessmentAttempt,
    setLatestAssessmentResponse,
    hideNavButtons = false,
    skipConfirmationDialog = false,
    autoProgress = true,
    responseDirection = 'row',
    responseStyle = 'column',
    retainProgress = false,
    sx = {}
}) => {
    const intl = useIntl()
    const theme = useTheme()
    const { timezone } = useAppSelector((state) => state.settings)
    const [submitResponseMutation] = useSubmitResponseMutation()

    const finalStep = step === assessmentAttempt.allQuestions.length

    const responseOptions = useMemo(
        () => assessmentItem.responses.sort(responseOptionOrderFn),
        [assessmentItem.responses]
    )

    const [selectedResponse, setSelectedResponse] =
        useState<ResponseInput>(undefined)

    useEffect(() => {
        if (
            retainProgress &&
            assessmentAttempt?.givenResponses &&
            assessmentAttempt?.givenResponses[step - 1]
        ) {
            const retainedResponse =
                assessmentAttempt?.givenResponses[step - 1]?.response
            if (retainedResponse?.value) {
                setSelectedResponse({
                    normalizedValue: retainedResponse.normalizedValue,
                    value: retainedResponse.value,
                    text: retainedResponse.text
                } as ResponseInput)
            }
        }
    }, [retainProgress, assessmentAttempt?.givenResponses, step])

    function handleBackClicked() {
        prevStep()
    }

    //activity type for avo event
    const activityType: ActivityTypeValueType = useMemo(() => {
        switch (assessmentAttempt?.type) {
            case AssessmentType.BaselineAssessment:
                return 'baseline_assessment_submitted'
            case AssessmentType.ProgressAssessment:
                return 'progress_response_submitted'
            default:
                return 'onboarding_response_submitted'
        }
    }, [assessmentAttempt?.type])

    function submitAssessmentResponse(responseInput?: ResponseInput) {
        const now = new Date()
        const response = submitResponseMutation({
            variables: {
                input: {
                    assessmentAttemptId: assessmentAttempt.assessmentAttemptId,
                    assessmentItemId: assessmentItem.id,
                    questionText: assessmentItem.questionText,
                    response: responseInput || selectedResponse,
                    userTime: { time: now, timezone: timezone }
                }
            }
        })

        if (!finalStep) {
            nextStep()
        }

        response?.then((r) => {
            Avo.surveySubmitted({
                featureType: 'assessment',
                activityType: activityType,
                assessmentId: assessmentAttempt.assessmentId,
                assessmentItemId: assessmentItem.id,
                assessmentAttemptId: assessmentAttempt.assessmentAttemptId,
                assessmentName: assessmentAttempt.name,
                assessmentVariant: assessmentAttempt.variant,
                isOnboarding: true,
                responseText: selectedResponse
                    ? selectedResponse.text
                    : undefined,
                responseValue: selectedResponse
                    ? selectedResponse.value
                    : undefined,
                dciCount: undefined,
                journey: undefined,
                journeyDay: undefined,
                journeyId: undefined,
                journeyLevel: undefined,
                npsScore: undefined,
                openAnswer: undefined,
                tabName: undefined,
                firstJourney: undefined,
                surveyLocation: undefined,
                messageQueueId: undefined,
                dispatchedDate: undefined,
                isSystemEvent: false
            })
            if (
                r.data?.assessment?.submitResponse?.__typename ===
                'AssessmentAttemptSuccess'
            ) {
                if (finalStep) {
                    nextStep()
                }
                setLatestAssessmentResponse(r.data.assessment.submitResponse)
            }
        })
    }

    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
        useState<boolean>(false)
    const closeConfirmationDialog = () => {
        setIsConfirmationDialogOpen(false)
    }

    const confirmConfirmationDialog = () => {
        submitAssessmentResponse()
        closeConfirmationDialog()
    }

    function handleNextClicked(responseInput?: ResponseInput) {
        if (finalStep) {
            // complete assessment, wait for last response submitted before closing
            if (skipConfirmationDialog) {
                submitAssessmentResponse(responseInput) // won't progress until final answer is submitted
            } else {
                setIsConfirmationDialogOpen(true)
            }
        } else {
            submitAssessmentResponse(responseInput)
        }
    }

    const handleOptionClicked = (e: any, option: ResponseOptionCms) => {
        const responseInput = {
            text: option.text,
            value: option.value,
            normalizedValue: option.normalizedValue
        }
        setSelectedResponse(responseInput)

        // option to auto-progress user only when click action is used, ignore for tab/arrow keys
        if (
            (autoProgress || hideNavButtons) &&
            e.pageX !== 0 &&
            e.pageY !== 0
        ) {
            setTimeout(() => handleNextClicked(responseInput), 200)
        }
    }

    const responseStyleProps = useMemo(() => {
        switch (responseStyle) {
            case 'radio':
                return {
                    justifyContent: 'left',
                    textAlign: 'left'
                }
            case 'button':
                return {
                    justifyContent: 'center',
                    textAlign: 'center'
                }
            default:
                return {}
        }
    }, [responseStyle])

    return (
        <Stack
            display={'flex'}
            gap={1}
            sx={sx}
            data-testid={'assessment-step-' + assessmentItem.id}
        >
            <FormControl fullWidth>
                <FormLabel
                    id={'assessment-item-' + assessmentItem.id + '-label'}
                >
                    <CoreTypography
                        variant={'h3'}
                        color={'text.primary'}
                        textAlign={'start'}
                        mb={2}
                    >
                        {assessmentItem.questionText}
                    </CoreTypography>
                </FormLabel>
                <RadioGroup
                    aria-labelledby={
                        'assessment-item-' + assessmentItem.id + '-label'
                    }
                    name={'assessment-form-' + assessmentItem.id}
                    sx={{
                        display: 'flex',
                        width: '100%',
                        flexFlow: responseDirection,
                        gap: '8px'
                    }}
                >
                    {responseOptions.map((option, i) => (
                        <FormControlLabel
                            key={assessmentItem.id + ' response ' + i}
                            id={assessmentItem.id + ' response ' + i}
                            value={option.value}
                            control={
                                <Radio
                                    onClick={(e) =>
                                        handleOptionClicked(e, option)
                                    }
                                    sx={{
                                        color: 'inherit !important',
                                        ...(responseStyle === 'button' && {
                                            padding: 0,
                                            '.MuiSvgIcon-root': {
                                                display: 'none'
                                            }
                                        })
                                    }}
                                />
                            }
                            label={
                                <CoreTypography
                                    customVariant={'buttonNormal'}
                                    width={'fit-content'}
                                >
                                    {option.text}
                                </CoreTypography>
                            }
                            checked={option.value === selectedResponse?.value}
                            aria-checked={
                                option.value === selectedResponse?.value
                            }
                            sx={{
                                borderRadius: `${theme.shape.borderRadius}px`,
                                width: '100%',
                                padding: 1,
                                minHeight: '44px',
                                m: 0,
                                ...(option.value === selectedResponse?.value
                                    ? {
                                          // colors based on selected state
                                          backgroundColor: 'secondary.main',
                                          color: 'secondary.contrastText'
                                      }
                                    : {
                                          backgroundColor:
                                              theme.palette.grey[100],
                                          color: 'text.primary'
                                      }),
                                ...{ responseStyleProps }
                            }}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
            {!hideNavButtons && (
                <Stack
                    direction={'row'}
                    justifyContent={'center'}
                    my={1}
                    gap={1}
                    width={'100%'}
                >
                    {step !== 1 && (
                        <Button onClick={handleBackClicked}>
                            <CoreTypography customVariant={'buttonNormal'}>
                                {intl.formatMessage(messages.backButtonLabel)}
                            </CoreTypography>
                        </Button>
                    )}
                    <Button
                        variant={'contained'}
                        onClick={() => handleNextClicked()}
                    >
                        <CoreTypography customVariant={'buttonNormal'}>
                            {intl.formatMessage(
                                finalStep
                                    ? messages.finishButtonLabel
                                    : messages.nextButtonLabel
                            )}
                        </CoreTypography>
                    </Button>
                </Stack>
            )}
            {!skipConfirmationDialog && (
                <LeafConfirmationDialog
                    dialogTitle={
                        <CoreTypography variant={'h3'}>
                            {intl.formatMessage(
                                messages.confirmationDialogTitle
                            )}
                        </CoreTypography>
                    }
                    dialogContent={
                        <CoreTypography variant={'body1'}>
                            {intl.formatMessage(
                                messages.confirmationDialogContent
                            )}
                        </CoreTypography>
                    }
                    primaryButtonText={intl.formatMessage(
                        messages.confirmationDialogConfirmation
                    )}
                    primaryButtonOnClick={confirmConfirmationDialog}
                    secondaryButtonText={intl.formatMessage(
                        messages.backButtonLabel
                    )}
                    secondaryButtonOnClick={closeConfirmationDialog}
                    onClose={closeConfirmationDialog}
                    open={isConfirmationDialogOpen}
                />
            )}
        </Stack>
    )
}

export default AssessmentStep
