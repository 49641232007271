import { LeafConfirmationDialog } from '@thriveglobal/thrive-web-leafkit'
import { FC, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import differenceInDays from 'date-fns/differenceInDays'
import { useZonedDate } from '../../../hooks/useZonedDateTime'
import startOfDay from 'date-fns/startOfDay'

const messages = defineMessages({
    dialogTitle: {
        defaultMessage: 'Leave your assessment?',
        description: 'Title of dialog to abandon baseline assessment'
    },
    dialogMessage: {
        defaultMessage:
            '{daysRemaining, plural, =0 {Your assessment responses will expire in less than a day. Until then, you can resume at any time. Complete your assessment to get your results.} one {Your assessment responses will expire in {daysRemaining, number} day. Until then, you can resume at any time. Complete your assessment to get your results.} other {Your assessment responses will expire in {daysRemaining, number} days. Until then, you can resume at any time. Complete your assessment to get your results.}}',
        description: 'Body of dialog to abandon baseline assessment quiz'
    },
    continueMessage: {
        defaultMessage: 'Continue assessment',
        description: 'Button title to resume taking the baseline assessment'
    },
    cancelMessage: {
        defaultMessage: 'Leave assessment',
        description: 'Button title to cancel taking the baseline assessment'
    }
})

export type AbandonAssessmentDialogProps = {
    isOpen: boolean
    handleOnClose: () => void
    handleOnContinue: () => void
    handleOnCancel: () => void
    assessmentCreationDate: Date
}
const DAYS_BEFORE_DELETION = 14

const AbandonAssessmentDialog: FC<AbandonAssessmentDialogProps> = ({
    isOpen,
    handleOnClose,
    handleOnCancel,
    handleOnContinue,
    assessmentCreationDate
}) => {
    const { formatMessage } = useIntl()

    const getZonedDate = useZonedDate()
    const daysRemainingCalc = useMemo(() => {
        const date = new Date()
        return (
            DAYS_BEFORE_DELETION -
            Math.abs(
                differenceInDays(
                    getZonedDate(startOfDay(date)),
                    new Date(assessmentCreationDate)
                )
            )
        )
    }, [DAYS_BEFORE_DELETION])

    return (
        <LeafConfirmationDialog
            dialogContent={formatMessage(messages.dialogMessage, {
                daysRemaining: daysRemainingCalc
            })}
            dialogTitle={formatMessage(messages.dialogTitle)}
            fullWidth
            primaryButtonOnClick={handleOnCancel}
            primaryButtonText={formatMessage(messages.cancelMessage)}
            secondaryButtonOnClick={handleOnContinue}
            secondaryButtonText={formatMessage(messages.continueMessage)}
            open={isOpen}
            onClose={handleOnClose}
            data-testid="AbandonAssessmentDialog"
        />
    )
}

export default AbandonAssessmentDialog
