import { useState } from 'react'
import { Button, LinearProgress, Stack, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import BaselineAssessmentDialog from '../BaselineAssessmentDialog'
import { defineMessages, useIntl } from 'react-intl'
import { useIsSmallScreen } from '../../../hooks/useIsSmallScreen'
import {
    AssessmentAttemptError,
    AssessmentAttemptSuccess,
    AssessmentItem
} from '../../../graphql/generated/autogenerated'
import { ApolloError } from '@apollo/client'
import { getAssessmentProgressCount } from '../../utils'
import { Avo } from '@thriveglobal/thrive-web-tracking'

const messages = defineMessages({
    baselineAssessmentCTATitle: {
        defaultMessage: 'Discover your well-being identity!',
        description:
            'Title for a call to action to discover your well-being identity.'
    },
    baselineAssessmentInProgressCTATitle: {
        defaultMessage: 'Resume your assessment',
        description:
            'Title for a call to action for a user to resume their assessment.'
    },
    baselineAssessmentCTADescription: {
        defaultMessage:
            'Your path towards Intention is unique. Help us know where you are by answering a series of short questions.',
        description:
            'Sub-title telling the user about the outcomes of taking a short survey and the duration of the survey.'
    },
    baselineAssessmentInProgressCTADescription: {
        defaultMessage:
            'Pick up where you left off and help us understand your progress by answering a few short questions.',
        description:
            'Sub-title describing how the user can continue to answer their assessment'
    },
    assessmentProgressCount: {
        defaultMessage: '{currentStep} of {totalSteps}',
        description:
            'Describes current step of total steps in process. Ex: 1 of 5'
    },
    learnMoreButtonLabel: {
        defaultMessage: 'Learn more',
        description:
            'Button label prompting the user to learn more about the assessment.'
    },
    takeAssessmentButtonLabel: {
        defaultMessage: 'Start assessment',
        description: 'Button label prompting the user to take their assessment.'
    },
    resumeAssessmentButtonLabel: {
        defaultMessage: 'Resume assessment',
        description:
            'Button label prompting the user to resume their in progress assessment'
    }
})

const IMAGE_URL =
    'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/3a56a278-b9ad-4b5d-22ab-e60990c76000/cms'
const IMAGE_HEIGHT = '259px'

interface BaselineAssessmentCTAProps {
    assessmentAttempt: AssessmentAttemptSuccess
    error: ApolloError
    assessmentAttemptError: AssessmentAttemptError
    loading: boolean
    assessmentItems: AssessmentItem[]
    onClose?: () => void
    size?: 'small' | 'default'
}

const BaselineAssessmentCTA: React.FC<BaselineAssessmentCTAProps> = ({
    assessmentAttempt,
    error,
    assessmentAttemptError,
    loading,
    assessmentItems,
    onClose = () => {},
    size = 'default'
}) => {
    const intl = useIntl()
    const theme = useTheme()
    const isSmallScreen = useIsSmallScreen() || size === 'small'
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

    const assessmentProgressCount =
        getAssessmentProgressCount(assessmentAttempt?.givenResponses) ?? 0
    const assessmentTotalQuestionsCount = assessmentItems?.length ?? 0
    const isInProgress = assessmentProgressCount > 0

    const [baselineAssessmentDialogOpen, setBaselineAssessmentDialogOpen] =
        useState<boolean>(false)
    const openBaselineAssessmentDialog = () => {
        setBaselineAssessmentDialogOpen(true)
        Avo.surveyStarted({
            featureType: 'assessment',
            activityType: 'baseline_assessment_started',
            isOnboarding: true,
            assessmentId: assessmentAttempt?.assessmentId,
            assessmentAttemptId: assessmentAttempt?.assessmentAttemptId,
            assessmentName: assessmentAttempt?.name,
            assessmentVariant: assessmentAttempt?.variant,
            isSystemEvent: false,
            onboardingAttemptNumber: null,
            responseText: null
        })
    }
    const closeBaselineAssessmentDialog = () => {
        onClose()
        setBaselineAssessmentDialogOpen(false)
    }

    return (
        <>
            <Stack
                direction={isSmallScreen ? 'column' : 'row'}
                gap={isSmallScreen ? 2 : 4}
                width={'100%'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <img height={IMAGE_HEIGHT} src={IMAGE_URL} alt={''} />
                <Stack gap={2} maxWidth={'480px'}>
                    <CoreTypography variant={'h2'}>
                        {intl.formatMessage(
                            isInProgress
                                ? messages.baselineAssessmentInProgressCTATitle
                                : messages.baselineAssessmentCTATitle
                        )}
                    </CoreTypography>
                    {isInProgress && (
                        <>
                            <LinearProgress
                                variant={'determinate'}
                                value={
                                    (assessmentProgressCount /
                                        assessmentTotalQuestionsCount) *
                                    100
                                }
                            />
                            <CoreTypography variant={'overline'}>
                                {intl.formatMessage(
                                    messages.assessmentProgressCount,
                                    {
                                        currentStep: assessmentProgressCount,
                                        totalSteps:
                                            assessmentTotalQuestionsCount
                                    }
                                )}
                            </CoreTypography>
                        </>
                    )}
                    <CoreTypography variant={'body1'}>
                        {intl.formatMessage(
                            isInProgress
                                ? messages.baselineAssessmentInProgressCTADescription
                                : messages.baselineAssessmentCTADescription
                        )}
                    </CoreTypography>
                    <Stack
                        direction={isMediumScreen ? 'column' : 'row'}
                        gap={3}
                    >
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            fullWidth={isMediumScreen}
                            onClick={openBaselineAssessmentDialog}
                        >
                            <CoreTypography customVariant={'buttonNormal'}>
                                {intl.formatMessage(
                                    isInProgress
                                        ? messages.resumeAssessmentButtonLabel
                                        : messages.takeAssessmentButtonLabel
                                )}
                            </CoreTypography>
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
            <BaselineAssessmentDialog
                assessmentItems={assessmentItems}
                assessmentAttemptError={assessmentAttemptError}
                assessmentAttempt={assessmentAttempt}
                loading={loading}
                error={error}
                open={baselineAssessmentDialogOpen}
                onClose={closeBaselineAssessmentDialog}
            />
        </>
    )
}

export default BaselineAssessmentCTA
