import { ApolloError } from '@apollo/client'
import { DialogContent } from '@mui/material'
import {
    LeafDialog,
    LeafDialogProps,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useState } from 'react'
import {
    AssessmentAttemptError,
    AssessmentAttemptSuccess,
    AssessmentItem
} from '../../../graphql/generated/autogenerated'
import { useIsSmallScreen } from '../../../hooks/useIsSmallScreen'
import AbandonAssessmentDialog from '../AbandonAssessmentDialog/index'
import { BaselineAssessmentResponse } from '../BaselineAssessment'
import { Assessment } from '../index'

export interface BaselineAssessmentDialogProps extends LeafDialogProps {
    assessmentAttempt: AssessmentAttemptSuccess
    error: ApolloError
    assessmentAttemptError: AssessmentAttemptError
    loading: boolean
    assessmentItems: AssessmentItem[]
}
const BaselineAssessmentDialog: React.FC<BaselineAssessmentDialogProps> = ({
    assessmentAttempt,
    error,
    assessmentAttemptError,
    assessmentItems,
    loading,
    ...dialogProps
}) => {
    const theme = useTheme()
    const isSmallScreen = useIsSmallScreen()
    const [isAbandonDialogOpen, setIsAbandonDialogOpen] = useState<boolean>()

    const onAssessmentComplete = (response: BaselineAssessmentResponse) => {
        dialogProps.onClose()
        if (response.completed) {
            //TODO: show success alert when completed or error alert when closed in error
        }
    }

    const closeBaselineAssessment = () => {
        setIsAbandonDialogOpen(true)
    }
    const onCloseAbandonDialog = () => {
        setIsAbandonDialogOpen(false)
    }
    const onContinueAssessment = () => {
        setIsAbandonDialogOpen(false)
        dialogProps.onClose()
    }

    return (
        <LeafDialog
            {...dialogProps}
            fullScreen
            PaperProps={{
                sx: {
                    background: theme.gradient.main,
                    padding: 0
                }
            }}
            onClose={closeBaselineAssessment}
        >
            <DialogContent
                data-testid={'baseline-assessment-dialog-content'}
                sx={{
                    mt: isSmallScreen ? 4 : 8,
                    maxWidth: theme.breakpoints.values.md,
                    width: '100%',
                    alignSelf: 'center'
                }}
            >
                <AbandonAssessmentDialog
                    isOpen={isAbandonDialogOpen}
                    handleOnClose={onCloseAbandonDialog}
                    handleOnContinue={onCloseAbandonDialog}
                    handleOnCancel={onContinueAssessment}
                    assessmentCreationDate={assessmentAttempt?.createdAt}
                />
                <Assessment
                    assessmentAttempt={assessmentAttempt}
                    error={error}
                    assessmentAttemptError={assessmentAttemptError}
                    loading={loading}
                    assessmentItems={assessmentItems}
                    onCompleteAssessment={onAssessmentComplete}
                    responseDirection={'column'}
                    skipConfirmationDialog
                    retainProgress
                    wrapperVariant={'dialog'}
                    sx={{ textAlignLast: 'center' }}
                />
            </DialogContent>
        </LeafDialog>
    )
}

export default BaselineAssessmentDialog
