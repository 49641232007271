import { Dialog, Skeleton } from '@mui/material'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import differenceInDays from 'date-fns/differenceInDays'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { OtherThriveApps } from '../../../enums'
import {
    AssessmentAttemptSuccess,
    AssessmentStatus
} from '../../../graphql/generated/autogenerated'
import useZonedDate from '../../../hooks/useZonedDateTime'
import {
    AssessmentSectionStatus,
    BaselineAssessmentCTA,
    BaselineAssessmentDialog,
    BaselineAssessmentSuperlativeCard,
    useGetOrCreateBaselineAssessmentAttempt
} from '../../index'

const HIDE_BASELINE_ASSESSMENT_AFTER_DAYS = 7
const HIDE_SUPERLATIVE_AFTER_DAYS = 1

interface BaselineAssessmentSectionProps {
    setHasBaselineAssessmentItemsToShow?: (val: boolean) => void
    handleAssessmentSectionStateChange?: (val: AssessmentSectionStatus) => void
    size?: 'small' | 'default'
}
const BaselineAssessmentSectionForHomePage: React.FC<
    BaselineAssessmentSectionProps
> = ({
    setHasBaselineAssessmentItemsToShow = () => {},
    size = 'default',
    handleAssessmentSectionStateChange = () => {}
}) => {
    const history = useHistory()
    const location = useLocation()
    const getZonedDate = useZonedDate()

    const [showSuperlativeCardDialog, setShowSuperlativeCardDialog] =
        useState(false)

    const showBaselineAssessmentDialog: boolean = useMemo(() => {
        const searchParams = new URLSearchParams(location.search)
        return searchParams.get('baselineAssessment') === 'true' || false
    }, [location.search])

    const onAssessmentAttemptLoaded = useCallback(
        (attemptSuccess: AssessmentAttemptSuccess) => {
            if (attemptSuccess.status !== AssessmentStatus.Completed) {
                Avo.surveyServed({
                    featureType: 'assessment',
                    activityType: 'baseline_assessment_served',
                    isOnboarding: true,
                    assessmentId: attemptSuccess.assessmentId,
                    assessmentAttemptId: attemptSuccess.assessmentAttemptId,
                    assessmentName: attemptSuccess.name,
                    assessmentVariant: attemptSuccess.variant,
                    userId_: null,
                    surveyLocation: null,
                    dispatchedDate: null,
                    isSystemEvent: false,
                    onboardingAttemptNumber: null
                })
            }
        },
        []
    )

    const {
        getOrCreateNewBaselineAssessment,
        assessmentItems,
        assessmentAttempt,
        loading,
        error,
        getInProgressOrCreateNewBaselineAssessmentError
    } = useGetOrCreateBaselineAssessmentAttempt(onAssessmentAttemptLoaded)

    const hasTakenBaselineAssessment = useMemo(() => {
        return assessmentAttempt?.status === AssessmentStatus.Completed ?? true
    }, [assessmentAttempt])

    useEffect(() => {
        getOrCreateNewBaselineAssessment()
    }, [getOrCreateNewBaselineAssessment])

    const today = getZonedDate()

    const hideBaselineAssessment = useMemo(() => {
        if (assessmentAttempt) {
            const assessmentCreatedDate = new Date(assessmentAttempt?.createdAt)

            const daysSinceCreation = differenceInDays(
                today,
                assessmentCreatedDate
            )
            return (
                hasTakenBaselineAssessment ||
                daysSinceCreation >= HIDE_BASELINE_ASSESSMENT_AFTER_DAYS
            )
        }
        return true
    }, [assessmentAttempt, hasTakenBaselineAssessment, today])

    const hideSuperlative = useMemo(() => {
        if (assessmentAttempt) {
            const assessmentLastUpdatedDate = new Date(
                assessmentAttempt?.updatedAt
            )
            const daysSinceCompletion = differenceInDays(
                today,
                assessmentLastUpdatedDate
            )
            return (
                !hasTakenBaselineAssessment ||
                daysSinceCompletion >= HIDE_SUPERLATIVE_AFTER_DAYS
            )
        }
        return true
    }, [assessmentAttempt, hasTakenBaselineAssessment, today])

    //TODO: Remove use effect once migration to new handler is complete
    useEffect(() => {
        setHasBaselineAssessmentItemsToShow(
            !hideSuperlative || !hideBaselineAssessment
        )
    }, [
        hideBaselineAssessment,
        hideSuperlative,
        setHasBaselineAssessmentItemsToShow
    ])

    useEffect(() => {
        setShowSuperlativeCardDialog(
            showBaselineAssessmentDialog && hasTakenBaselineAssessment
        )
    }, [showBaselineAssessmentDialog, hasTakenBaselineAssessment])

    useEffect(() => {
        if (loading || assessmentAttempt === undefined) {
            handleAssessmentSectionStateChange(AssessmentSectionStatus.Loading)
        } else if (!hideSuperlative) {
            handleAssessmentSectionStateChange(
                AssessmentSectionStatus.ServingSuperlative
            )
        } else if (!hideBaselineAssessment) {
            handleAssessmentSectionStateChange(
                AssessmentSectionStatus.ServingBaselineAssessment
            )
        } else {
            handleAssessmentSectionStateChange(AssessmentSectionStatus.Empty)
        }
    }, [
        assessmentAttempt,
        handleAssessmentSectionStateChange,
        hideBaselineAssessment,
        hideSuperlative,
        loading
    ])

    const onCloseBaselineAssessmentDialog = useCallback(() => {
        history.push(OtherThriveApps.ThriveHome)

        if (!hasTakenBaselineAssessment) {
            getOrCreateNewBaselineAssessment().then(({ data }) => {
                const attempt = data?.assessment
                    ?.getOrCreateBaselineAssessmentAttempt as AssessmentAttemptSuccess

                if (attempt?.status === AssessmentStatus.Completed) {
                    setShowSuperlativeCardDialog(true)
                }
            })
        }
    }, [getOrCreateNewBaselineAssessment, hasTakenBaselineAssessment, history])

    if (loading) {
        return (
            <Skeleton
                variant={'rounded'}
                width={'100%'}
                height={'300px'}
                data-testid={'baseline-assessment-for-home-page-loader'}
            />
        )
    }

    return (
        <>
            {!hideBaselineAssessment && (
                <BaselineAssessmentCTA
                    assessmentAttempt={assessmentAttempt}
                    assessmentItems={assessmentItems ?? []}
                    assessmentAttemptError={
                        getInProgressOrCreateNewBaselineAssessmentError
                    }
                    loading={loading}
                    error={error}
                    onClose={onCloseBaselineAssessmentDialog}
                    size={size}
                />
            )}
            {!hideSuperlative && assessmentAttempt?.superlative && (
                <BaselineAssessmentSuperlativeCard
                    superlative={assessmentAttempt?.superlative}
                    size={size === 'small' ? size : 'medium'}
                />
            )}
            {assessmentAttempt && (
                <BaselineAssessmentDialog
                    assessmentAttempt={assessmentAttempt}
                    error={error}
                    assessmentAttemptError={
                        getInProgressOrCreateNewBaselineAssessmentError
                    }
                    loading={loading}
                    assessmentItems={assessmentItems}
                    open={
                        showBaselineAssessmentDialog &&
                        !hasTakenBaselineAssessment
                    }
                    onClose={onCloseBaselineAssessmentDialog}
                />
            )}
            <Dialog
                open={showSuperlativeCardDialog}
                onClose={() => setShowSuperlativeCardDialog(false)}
                maxWidth="lg"
                data-testid="superlative-dialog"
                PaperProps={{ sx: { maxWidth: 610 } }}
            >
                <BaselineAssessmentSuperlativeCard
                    canClose={true}
                    superlative={assessmentAttempt?.superlative}
                    onClose={() => setShowSuperlativeCardDialog(false)}
                />
            </Dialog>
        </>
    )
}

export default BaselineAssessmentSectionForHomePage
